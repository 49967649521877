.privacy-policy {
    background-color: #000; /* Background color for privacy policy section */
    color: #fff; /* Text color */
    padding-top: 200px; /* Adds space below the fixed navbar */
    padding-bottom: 60px; /* Standard bottom padding */
}

.privacy-policy h2 {
    font-size: 40px;
    margin-bottom: 24px;
}

.privacy-policy ul {
    margin-left: 20px;
}

.privacy-policy ul li {
    margin-bottom: 8px;
    line-height: 1.6;
}

.privacy-policy a {
    color: #4317EA;
    text-decoration: none;
}

.privacy-policy a:hover {
    text-decoration: underline;
}



/*Custom Fonts */
@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face{
font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face{
font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}
/*Default CSS*/

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #000000 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
/*Dropdown menu that scrolls with the page  */
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #000000;
}
nav.navbar a.navbar-brand {
    width: 9%; /*logo size*/
}
/*Navbar buttons*/
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center; /*center text */
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px; /*round icons we want same width and height */
    height: 42px;
    background: rgba(0, 0, 0, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center; 
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1); /*hovering shows bigger icon */
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button { /*for lets connect button that takes us to the contact form */
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff; /*border indicates button because it is transparent */
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg); /*expanded menu rotate 45 degrees */
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg); 
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/blue-background.svg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(200, 185, 194, 0.5) -5.91%, rgba(32, 31, 36, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  min-height: 10;
}


.secondHeader 
{
  color: #4317EA;
}
.banner p {
  color: #4317EA;
  font-size: 35px;
  font-weight: 450;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/*Service CSS*/
.section-black {
	background: #23128D;
	padding: 70px 0;
}
.section-black img{
  width: 20%;
}

.section-black h2 {

  font-size: 45px;
	font-weight: 700;

}
.section-black p{
  font-size: 18px;
}

.service-item{
	background: #050505;
	text-align:center;
	margin:20px 0;
	padding:50px 20px 80px 20px;
	border-radius: 8px 8px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}


.service-item:hover{
	background: #23128D;
	color: white;
}

.service-item h3{
	margin:20px 0 1px 0!important;
	color:#4317EA;
	font-size:22px;
	font-weight: 700;
	text-transform:uppercase;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}



.section-subtitle {
	white-space: pre-line;
 }

.service-item:hover h3{
	color:#ddd;
}

.service-info {
	display: block;
	margin-bottom:0;
}

.service-info p{
	color:#acacac;
	font-family: Centra;
}

.service-info::after {
    background: #008aff;
    background: -webkit-linear-gradient(135deg, #203aad 0%, #23128D 100%);
    background: -o-linear-gradient(bottom right, #6B02FF, #23128D);
    background: -moz-linear-gradient(bottom right, #008aff, #1ad2fd);
    background: linear-gradient(135deg, #6B02FF 0%, #23128D 100%);
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
}





/************ Wins Css ************/
.win {
  padding: 0 0 50px 0;
  position: relative;
}


.win-bx {
  background: #000000;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: 60px;
}
.win h2 {
	font-size: 45px;
	font-weight: 700;
  color:#4317EA;
}

.win h3 {
	font-size: 45px;
	font-weight: 700;
  color:#ea1717;
}

.win h5
{
  color:#4317EA;
  font-weight: 700;
  font-size: 22px;
}


.win p {
    color: #fafafa;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0; 
    padding-right: 25px;
    padding-left: 40px;
}
.win-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.win-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}



/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #232022 -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #030303 -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}


/************ Contact Form Css ************/
.contact {
  background: linear-gradient(90.21deg, #000000 -5.91%, #000000 111.58%);
  padding: 60px 0 100px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  color: #4317EA;
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: #4317EA;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-color: #000;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


.footer img {
  width: 20%;
}

/*Slider CSS*/
.slider{
  background: #23128D;
  position: relative;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider h3{
  color: red;
  padding-left: 200px;
}

.image{
  width: 900px;
  height: 600px;
  border-radius: 10px;
}
.right-arrow{
  position:absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color:rgba(0, 0, 0, 0.256);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow{
  position:absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color:rgba(0, 0, 0, 0.256);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide{
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
